// src/App.js
import React, { useState, useEffect } from 'react';
import HomePage from './HomePage';
import TrainInfo from './TrainInfo';
import StationInfo from './StationInfo';
import Modal from './Modal';
import './App.css';

function App() {
  const [viewMode, setViewMode] = useState('home');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [previousMode, setPreviousMode] = useState(null);

  const handleSelectMode = (mode) => {
    if (mode === viewMode) return;
    
    setPreviousMode(viewMode);
    setIsTransitioning(true);
    
    // 短暫延遲以允許退出動畫
    setTimeout(() => {
      setViewMode(mode);
      setIsTransitioning(false);
    }, 300);
  };

  const handleBackToHome = () => {
    handleSelectMode('home');
  };

  // 動態更改背景漸變
  useEffect(() => {
    const app = document.querySelector('.app');
    
    if (viewMode === 'train') {
      app.style.backgroundImage = `
        radial-gradient(circle at 80% 20%, rgba(0, 229, 255, 0.15) 0%, transparent 30%),
        radial-gradient(circle at 20% 80%, rgba(255, 0, 221, 0.15) 0%, transparent 30%)
      `;
    } else if (viewMode === 'station') {
      app.style.backgroundImage = `
        radial-gradient(circle at 30% 70%, rgba(0, 229, 255, 0.15) 0%, transparent 30%),
        radial-gradient(circle at 70% 30%, rgba(255, 0, 221, 0.15) 0%, transparent 30%)
      `;
    } else {
      app.style.backgroundImage = `
        radial-gradient(circle at 10% 20%, rgba(0, 229, 255, 0.15) 0%, transparent 30%),
        radial-gradient(circle at 90% 80%, rgba(255, 0, 221, 0.15) 0%, transparent 30%)
      `;
    }
  }, [viewMode]);

  return (
    <div className="app">
      <div className={`container ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
        {viewMode === 'home' && <HomePage onSelectMode={handleSelectMode} />}
        {viewMode !== 'home' && (
          <div className="header">
            <h2>{viewMode === 'train' ? '車輛動態查詢' : '車站資訊查詢'}</h2>
            <button className="back-button" onClick={handleBackToHome}>
              返回首頁
            </button>
          </div>
        )}
        {viewMode === 'train' && <TrainInfo />}
        {viewMode === 'station' && <StationInfo />}
      </div>
      <Modal 
        isOpen={isModalOpen}
        message={modalMessage}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default App;