// src/TrainDetailsModal.js
import React, { useState, useEffect, useRef } from 'react';
import './TrainDetailsModal.css';

const TrainDetailsModal = ({ isOpen, onClose, train, currentStation }) => {
  const [liveTrainInfo, setLiveTrainInfo] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const currentStopRef = useRef(null);

  useEffect(() => {
    if (isOpen && train && train.TrainTimetables && train.TrainTimetables[0]) {
      const fetchLiveTrainInfo = async () => {
        try {
          const trainNo = train.TrainTimetables[0].TrainInfo.TrainNo;
          const response = await fetch(`https://train-query-backend.vercel.app/api/train/${trainNo}`);
          if (!response.ok) {
            throw new Error('獲取列車即時資料失敗');
          }
          const data = await response.json();
          setLiveTrainInfo(data);
        } catch (error) {
          console.error('Error fetching live train info:', error);
        }
      };

      fetchLiveTrainInfo();
    }
  }, [isOpen, train]);

  // 重置關閉狀態當模態框打開時
  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
    }
  }, [isOpen]);

  // 當有當前站點時，滾動到該站點位置
  useEffect(() => {
    if (currentStopRef.current) {
      setTimeout(() => {
        currentStopRef.current.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'center' 
        });
      }, 500);
    }
  }, [liveTrainInfo]);

  const handleClose = () => {
    setIsClosing(true);
    // 等待動畫完成後才真正關閉模態框
    setTimeout(() => {
      onClose();
    }, 300);
  };

  if (!isOpen || !train || !train.TrainTimetables || !train.TrainTimetables[0]) return null;

  const currentTime = new Date();

  const getDelayedTime = (originalTime, delayMinutes) => {
    const [hours, minutes] = originalTime.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes + delayMinutes);
    return date.toTimeString().slice(0, 5);
  };

  const getCurrentStationInfo = () => {
    if (!liveTrainInfo || !liveTrainInfo.TrainLiveBoards || liveTrainInfo.TrainLiveBoards.length === 0) {
      return '無法獲取當前車站信息';
    }
    const liveInfo = liveTrainInfo.TrainLiveBoards[0];
    let status;
    switch(liveInfo.TrainStationStatus) {
      case 0:
        status = '離開';
        break;
      case 1:
        status = '到達';
        break;
      case 2:
        status = '進行中';
        break;
      default:
        status = '未知';
    }
    return `當前位置: ${liveInfo.StationName.Zh_tw}, 狀態: ${status}`;
  };

  const trainInfo = train.TrainTimetables[0].TrainInfo;
  const delayTime = liveTrainInfo && liveTrainInfo.TrainLiveBoards && liveTrainInfo.TrainLiveBoards[0]
    ? liveTrainInfo.TrainLiveBoards[0].DelayTime : 0;
  
  // 找出當前站點
  const currentStationName = liveTrainInfo && liveTrainInfo.TrainLiveBoards && liveTrainInfo.TrainLiveBoards[0]
    ? liveTrainInfo.TrainLiveBoards[0].StationName.Zh_tw
    : null;
  
  // 火車SVG圖標
  const trainSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 20" fill="white">
    <path d="M35,5H5C2.24,5,0,7.24,0,10s2.24,5,5,5h30c2.76,0,5-2.24,5-5S37.76,5,35,5z M7.5,12.5c-1.38,0-2.5-1.12-2.5-2.5
    s1.12-2.5,2.5-2.5S10,8.62,10,10S8.88,12.5,7.5,12.5z M32.5,12.5c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5
    S33.88,12.5,32.5,12.5z"/>
  </svg>
`;

  return (
    <div className={`train-details-modal-overlay ${isClosing ? 'closing' : ''}`} onClick={handleClose}>
      <div className="train-details-modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={handleClose}>&times;</button>
        <h2>車次 {trainInfo.TrainNo} 詳細資訊</h2>
        <div className="train-info">
          <p><strong>車種:</strong> {trainInfo.TrainTypeName.Zh_tw}</p>
          <p><strong>起點:</strong> {trainInfo.StartingStationName.Zh_tw}</p>
          <p><strong>終點:</strong> {trainInfo.EndingStationName.Zh_tw}</p>
          <p><strong>延誤:</strong> {liveTrainInfo && liveTrainInfo.TrainLiveBoards && liveTrainInfo.TrainLiveBoards[0] 
            ? `${liveTrainInfo.TrainLiveBoards[0].DelayTime} 分鐘` 
            : '獲取中...'}
          </p>
          <p><strong>當前狀態:</strong> {getCurrentStationInfo()}</p>
          <p><strong>更新時間:</strong> {liveTrainInfo ? new Date(liveTrainInfo.UpdateTime).toLocaleString() : '獲取中...'}</p>
        </div>
        <div className="stop-times-container">
          <div className="stop-times">
            {/* 時間線 */}
            <div className="timeline"></div>
            
            <div className="stop-times-header">
              <span>停靠站點</span>
              <span>時間</span>
            </div>
            
            {train.TrainTimetables[0].StopTimes.map((stop, index) => {
              const delayedTime = delayTime > 0 
                ? getDelayedTime(stop.DepartureTime, delayTime)
                : null;
              const delayedDateTime = delayedTime 
                ? new Date(`${train.TrainDate} ${delayedTime}`)
                : new Date(`${train.TrainDate} ${stop.DepartureTime}`);
              const isPast = delayedDateTime < currentTime;
              const isCurrent = stop.StationName.Zh_tw === currentStationName;
              
              return (
                <div 
                  key={index} 
                  className={`stop-time ${isPast ? 'past' : ''} ${isCurrent ? 'current' : ''}`}
                  ref={isCurrent ? currentStopRef : null}
                >
                  {/* 站點圓圈 */}
                  <div className="station-circle"></div>
                  
                  {/* 火車位置指示器 */}
                  {isCurrent && (
                    <>
                      {/* 火車指示器背景 - 用於遮蓋時間線 */}
                      <div className="train-indicator-bg"></div>
                      
                      {/* 火車位置指示器 */}
                      <div 
                        className="train-indicator"
                        dangerouslySetInnerHTML={{ __html: trainSvg }}
                      ></div>
                    </>
                  )}
                  
                  <div className="station-content">
                    <span className="station-name">{stop.StationName.Zh_tw}</span>
                    <div className="time-info">
                      <span className="scheduled-time">{stop.DepartureTime}</span>
                      {delayedTime && delayTime > 0 && (
                        <span className="delayed-time">預計 {delayedTime}</span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainDetailsModal;